import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { blobKey } from 'app/contants';
import { Achieve, AchieveCradsDTO } from 'app/modules/my-life/achieve/models/achieve';
import { AchieveApiService } from 'app/shared/services/generics/achieve-api.service';
import { BehaviorSubject, Observable, Subject, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AchieveDataService extends AchieveApiService {
  public messageSource = new Subject<string>();
  private _achievesCards: BehaviorSubject<AchieveCradsDTO> = new BehaviorSubject<AchieveCradsDTO>(null);
  private _achieves: BehaviorSubject<Achieve[]> = new BehaviorSubject<Achieve[]>(null);

  get achievesCards$(): Observable<AchieveCradsDTO> {
    return this._achievesCards.asObservable();
  }

  get achieves$(): Observable<Achieve[]> {
    return this._achieves.asObservable();
  }

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  createAchieve(achieve: Achieve) {
    return this.post("Achieve", achieve).pipe(
      switchMap(res => {
        return this.getAchieveByUser({ userId: achieve.user });
      })
    );;
  }

  getAchieveByUser(filter?) {
    return this.post("Achieve/ByUser", filter).pipe(
      tap((res: AchieveCradsDTO) => {
        res.highPriorityCards?.forEach(e => {
          e.achieveImageUrl = e.achieveImageUrl + blobKey;
        });

        res.completedCards?.forEach(e => {
          e.achieveImageUrl = e.achieveImageUrl + blobKey;
        });

        res.lowPriorityCards?.forEach(e => {
          e.achieveImageUrl = e.achieveImageUrl + blobKey;
        });

        res.teamCards?.forEach(e => {
          e.achieveImageUrl = e.achieveImageUrl + blobKey;
        });
        this._achievesCards.next(res);
        return res;
      })
    );
  }

  getAchieves(data: any) {
    return this.post("Achieve/get-all", data).pipe(
      tap(res => {
        res.forEach(e => {
          e.achieveImageUrl = e.achieveImageUrl + blobKey;
        });
        this._achieves.next(res);
        return res;
      })
    );
  }

  getAchieveDetails(id: any) {
    return this.get(`Achieve/${id}`);
  }

  submitAchieve(data: any) {
    return this.post(`/Achieve`, data);
  }

  updateAchieve(id: any, index: any, data: any) {
    return this.put(`AchievePlan/${id}/${index}`, data);
  }

  getImageAsBase64(url: string): Promise<string> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .toPromise()
      .then((blob: any) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }

  deleteAchieve(achieve: Achieve): Observable<any> {
    return this.delete("Achieve/" + achieve.id).pipe(
      switchMap(res => {
        return this.getAchieveByUser({ userId: achieve.user });
      })
    );
  }

  updateAchieveViews(achieveId, userId) {
    return this.post("Achieve/update-views?achieveId=" + achieveId + "&userId=" + userId, {});
  }

  setAchievePriority(achieveId, priority) {
    return this.put("Achieve/set-priority?achieveId=" + achieveId + "&priority=" + priority, {});
  }
}
