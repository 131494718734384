import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OwxSignalRService {
  public connectionId = '';
  private hubConnection: signalR.HubConnection;
  private receivedMessagesSubject: Subject<any> = new Subject<any>();

  constructor() {
    let hubUrl = `${environment.owxSectionApiUrl}/chathub`;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          const token = localStorage.getItem('accessToken');
          return token ? `Bearer ${token}` : '';
        },
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  public startSignalRConnection() {
    this.hubConnection
      .start()
      .then(() => {
        console.log('Owx Hub connection started.');
        this.registerEventsHandler();
      })
      .catch((err) => console.error('Error while starting SignalR connection:', err));
  }

  private registerEventsHandler() {
    this.hubConnection.on('ReceiveMessage', (message: any) => {
      this.receivedMessagesSubject.next(message);
    });

    this.hubConnection.onreconnected((connectionId) => {
      this.getConnectionId();
    });

    this.hubConnection.onreconnecting((err) => {
      console.log('Reconnecting:', err);
    });

    this.hubConnection.onclose((err) => {
      console.log('Connection closed:', err);
      this.reconnect();
    });

    this.getConnectionId();
  }

  private getConnectionId() {
    this.hubConnection.invoke('GetConnectionId')
      .then((connectionId) => {
        this.connectionId = connectionId;
        console.log('ConnectionId:', this.connectionId);
        this.updateConnectionId(connectionId);
      })
      .catch((err) => console.error('Error while getting connection ID:', err));
  }

  public reconnect() {
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      this.startSignalRConnection();
    }
  }

  public disconnect() {
    this.hubConnection.stop().then(() => {
      console.log('Owx Hub connection stopped.');
    }).catch((err) => console.error('Error while stopping SignalR connection:', err));
  }

  public sendMessage(message: any) {
    this.hubConnection.invoke('SendMessage', message)
      .catch((err) => console.error('Error while sending message:', err));
  }

  public getMessagesObservable() {
    return this.receivedMessagesSubject.asObservable();
  }

  public updateConnectionId(connectionId: string) {
    this.hubConnection.invoke('UpdateConnectionId', connectionId)
      .catch((err) => console.error('Error while updating the connection ID:', err));
  }
}