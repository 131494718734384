export const environment = {
    
    // for local Backend API   
    // owxSectionApiUrl: 'https://localhost:5058',
    // achieveSectionApiUrl: 'https://localhost:5004',
    // acheiveApiUrl: 'http://localhost:7131',
    // filemanageApiUrl: 'https://localhost:7288',
    // chatAiApiUrl: 'https://localhost:5188',
    // owxWebUrl: 'http://localhost:4200',
	// notificationUrl:'http://localhost:5076'

    // for cloud Backend API
    owxSectionApiUrl: 'https://api.m1w.com/owxsection',
    achieveSectionApiUrl: 'https://api.m1w.com/achievesection',
    acheiveApiUrl: 'https://api.m1w.com/achieve',
    filemanageApiUrl: 'https://api.m1w.com/filemanage',
    chatAiApiUrl: 'https://api.m1w.com/chatai',
    owxWebUrl: 'https://oneworldx.com',
	notificationUrl:'https://api.m1w.com/owxservices'
};