import { Inject, Injectable } from '@angular/core';
import {
    MSAL_GUARD_CONFIG,
    MsalGuardConfiguration,
    MsalService,
} from '@azure/msal-angular';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';
import { Role, User, UserRole } from './user.types';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from 'app/modules/auth/auth.config';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private apiUrl = environment.owxSectionApiUrl;
    private _user: User | any = null;
    private _user$: BehaviorSubject<User | any> = new BehaviorSubject<User>(
        this._user
    );
    private roles: Role[] = [];

    constructor(
        private http: HttpClient,
        private msalService: MsalService,
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration
    ) {}

    set user(value: User) {
        this._user$.next(value);
        this._user = value;
        localStorage.setItem('user', JSON.stringify(value));
    }

    get user(): User {
        const u = localStorage.getItem('user');
        if (u === 'undefined') return undefined;
        return JSON.parse(u);
    }

    get user$(): Observable<User> {
        return this._user$.pipe(shareReplay());
    }

    get accessToken() {
        return localStorage.getItem('accessToken');
    }

    async isAdmin(): Promise<boolean> {
        const roles = await this.getUserRoles().toPromise();
        return roles.some((e) => e.type === UserRole.Admin);
    }

    getUser(id: string): Observable<User> {
        return this.http.get<User>(this.apiUrl + '/User/' + id);
    }

    create(user: User): Observable<any> {
        return this.http.post(this.apiUrl + '/User/Header', user);
    }

    delete(id: string): Observable<any> {
        return this.http.delete(this.apiUrl + '/User?id=' + id);
    }

    assignRoleToUser(role: Role): Observable<any> {
        return this.http.post(this.apiUrl + '/User/create-role', role);
    }

    getUserRoles(): Observable<any> {
        return this.http
            .get(this.apiUrl + '/User/get-roles?UserId=' + this.user.id)
            .pipe(
                tap((res) => {
                    this.roles.push(res);
                    return res;
                })
            );
    }

    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(this.apiUrl + '/User').pipe(
            tap((res) => {
                res.map((e) => {
                    e.displayName = e.firstName + ' ' + e.lastName;
                    e.selected = false;
                });
                return res;
            })
        );
    }

    async setActiveAccountAndInitializeUserData(accounts) {
        this.msalService.instance.setActiveAccount(accounts[0]);
        this.getClaims(
            this.msalService.instance.getActiveAccount().idTokenClaims
        );

        const response = await this.msalService.instance.acquireTokenSilent({
            scopes: [
                'https://oneworldxb2c.onmicrosoft.com/997c59c9-573d-4b03-888e-c17fff380861/weather.read',
            ],
        });
        localStorage.setItem('accessToken', response.accessToken);
    }

    getClaims(claims) {
        let user: User = new User();
        user.id = claims['oid'];
        user.email = claims['emails'][0];
        user.firstName = claims['extension_FirstName'].toString();
        user.lastName = claims['extension_LastName'].toString();
        user.displayName =
            claims['extension_FirstName'] + ' ' + claims['extension_LastName'];
        this.user = user;
    }

    login() {
        if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({
                ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
        } else {
            this.msalService.loginRedirect();
        }
    }

    logout() {
        this.msalService.logoutRedirect({
            postLogoutRedirectUri: environment.owxWebUrl,
        });

        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
    }

    register() {
        let registerFlowRequest: RedirectRequest | PopupRequest = {
            scopes: [],
            authority: b2cPolicies.authorities.signUp.authority,
        };
        this.msalService.loginRedirect(registerFlowRequest);
    }

    async isLoginSuccessfull() {
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length > 0) {
            await this.setActiveAccountAndInitializeUserData(accounts);
            return true;
        }

        return false;
    }

    udpateUserProfileInfo(data) {
        return this.http.post(this.apiUrl + '/User/update-user-info', data);
    }
}
