import { Injectable } from "@angular/core";
import { NotificationApiService } from "app/shared/services/generics/notification-api.service";
import { Observable } from "rxjs";
import { Notification } from "../models/notification";

@Injectable({
    providedIn: 'root',
})
export class NotificationDataService extends NotificationApiService {
    getNotiticationsByUser(userId: string): Observable<Notification[]> {
        return this.get("Notification/ByUser/" + userId);
    }

    markAllRead(): Observable<any> {
        return this.post("Notification/mark-all-read?isRead=" + true, {});
    }

    markAsRead(notificationId: string, isRead: boolean): Observable<any> {
        return this.post(`Notification/mark-as-read?notificationId=${notificationId}&isRead=${isRead}`, {});
    }
}