import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ChatAssistantHistoryDialogComponent } from 'app/modules/ai-assistant/chat-assistant-history-dialog/chat-assistant-history-dialog.component';
import { NotificationDataService } from 'app/modules/notification/services/notification-data.service';
import { NotificationSignalRService } from 'app/shared/services/notification-signarl.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { UserService } from '../models/user/user.service';

@Component({
    selector: 'owx-layout',
    templateUrl: './owx-layout.component.html',
    styleUrls: ['./owx-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OwxLayoutComponent implements OnInit, OnDestroy {
    showFiller = true;
    @ViewChild(MatDrawer) drawer: any;
    @ViewChild(MatDrawer) drawer1: any;
    isExpanded: any = 240;
    rightPanel: boolean = false;
    isHamburgerMenuOpen = false;
    value = '';
    isScreenSmall: boolean;
    page = '';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    menu = {
        mylife: [
            {
                // title: 'TrueSelf',
                title: 'Evoke',
                route: '/my-life/true-self/board',
                // icon: 'home',
                // icon: 'psychology',
                icon: 'self_improvement',
                expand: false,
                isSubmenu: false,
            },
            {
                // title: 'Vision',
                title: 'Envision',
                route: '/my-life/vision/board',
                // icon: 'visibility',
                icon: 'preview',
                expand: false,
                isSubmenu: false,
            },
            {
                // title: 'Achieve',
                title: 'Execute',
                route: '/my-life/achieve/board',
                // icon: 'assignment',
                icon: 'fact_check',
                expand: false,
                isSubmenu: true,
            },
        ],
        community: [
            {
                title: 'City',
                route: '/community/city',
                icon: 'location_city',
                expand: false,
                isSubmenu: false,
            },
            {
                title: 'Answer',
                route: '/community/answer',
                icon: 'question_answer',
                expand: false,
                isSubmenu: false,
            },
            {
                title: 'Job',
                route: '/community/job',
                // icon: 'chrome_reader_mode',
                icon: 'home_repair_service',
                expand: false,
                isSubmenu: false,
            },
            {
                title: 'Donate',
                route: '/community/donate',
                // icon: 'invert_colors',
                icon: 'volunteer_activism',
                expand: false,
                isSubmenu: false,
            },
            {
                title: 'Lend',
                route: '/community/lend',
                // icon: 'receipt',
                icon: 'payments',
                expand: false,
                isSubmenu: false,
            },

            // {
            //     title: 'Connect',
            //     route: '/community/connect',
            //     icon: 'device_hub',
            //     expand: false,
            //     isSubmenu: false,
            // },
        ],
        explore: [
            {
                title: 'Public Xcard',
                route: '/explore/xcard',
                // icon: 'card_membership',
                icon: 'rectangle',
                expand: false,
                isSubmenu: false,
            },
            // {
            //     title: 'Blog',
            //     route: '/explore/blog',
            //     icon: 'assignment_ind',
            //     expand: false,
            //     isSubmenu: false,
            // },
            // {
            //     title: 'Faq',
            //     route: '/explore/faq',
            //     icon: 'contact_support',
            //     expand: false,
            //     isSubmenu: false,
            // },
            // {
            //     title: 'Video',
            //     route: '/explore/video',
            //     icon: 'ondemand_video',
            //     expand: false,
            //     isSubmenu: false,
            // },
        ],
        connect: [
            {
                title: 'Inbox',
                route: '/connect/chat',
                // icon: 'group',
                icon: 'inbox',
                expand: false,
                isSubmenu: false,
            },
            {
                // title: 'People',
                title: 'Person',
                route: '/connect/people',
                // icon: 'group',
                icon: 'person',
                expand: false,
                isSubmenu: false,
            },
            {
                title: 'Expert',
                route: '/connect/expert',
                icon: 'account_circle',
                expand: false,
                isSubmenu: false,
            },
            {
                title: 'Business',
                route: '/connect/business',
                icon: 'store',
                expand: false,
                isSubmenu: false,
            },
            {
                title: 'Group',
                route: '/connect/group',
                icon: 'group',
                expand: false,
                isSubmenu: false,
            },
        ],
    };
    isDesktop: boolean = true;
    width = 500;
    currentRoute: any;
    notificationCount: number = 0;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private msalService: MsalService,
        public router: Router,
        private dialog: MatDialog,
        private notificationService: NotificationSignalRService,
        private notificationDataService: NotificationDataService,
        private userService: UserService
    ) {
        this.listenToRouterEvents();

        this.notificationService.startSignalRConnection();

        this.notificationService
            .getNotificationObservable()
            .subscribe((notifications) => {
                this.notificationCount += 1;
            });

        this.notificationDataService
            .getNotiticationsByUser(this.userService.user.id)
            .subscribe((notifications) => {
                if (notifications.length > 0) {
                    this.notificationCount = notifications.filter(
                        (e) => !e.read
                    ).length;
                }
            });
    }

    private listenToRouterEvents() {
        this._router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                if (event.urlAfterRedirects.startsWith('/my-life')) {
                    this.page = 'mylife';
                } else if (event.urlAfterRedirects.startsWith('/community')) {
                    this.page = 'community';
                } else if (event.urlAfterRedirects.startsWith('/explore')) {
                    this.page = 'explore';
                } else if (event.urlAfterRedirects.startsWith('/connect')) {
                    this.page = 'connect';
                } else {
                    this.page = '';
                }
            }
        });
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }
    onResizeEnd(event: any): void {
        this.width = event.rectangle.width;
        if (event.rectangle.width < 500) {
            this.width = 500;
        }
    }
    ngOnInit(): void {
        //  console.log('Current Route:', currentRoute);
        this.router.events.subscribe((event) => {
            this.currentRoute = this.router.url;
        });
        if (window.innerWidth < 992) {
            this.isDesktop = false;
            this.showFiller = false;
        } else {
            this.isDesktop = true;
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth < 992) {
                this.isDesktop = false;
                this.showFiller = false;
            } else {
                this.isDesktop = true;
            }
        });
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleHamburgerMenu() {
        this.isHamburgerMenuOpen = !this.isHamburgerMenuOpen;
    }

    closeHamburgerMenu() {
        this.isHamburgerMenuOpen = false;
    }
    closeMenu(event: any) {
        console.log(event);
        this.isHamburgerMenuOpen = event;
    }
    isDrawerOpen() {
        return this.drawer1?.opened;
    }
    changeSidebar() {
        this.showFiller = !this.showFiller;
        this.drawer = !this.drawer;
    }

    checkCurrentRoute(link: any) {
        const currentRoute = this.router.routerState.snapshot.url;
        let result = currentRoute.substring(1);
        if (link == result) {
            return true;
        } else {
            return false;
        }
    }

    checkCurrentRoute2() {
        const currentRoute = this.router.routerState.snapshot.url;
        return (
            currentRoute.includes('/true-self-board') ||
            currentRoute.includes('/true-self-template')
        );
    }

    rightMenuToggle() {
        this.rightPanel = !this.rightPanel;
        if (this.rightPanel) {
            this.showFiller = false;
            this.isHamburgerMenuOpen = false;
        }
    }

    logout() {
        this.msalService.logoutRedirect({
            postLogoutRedirectUri: environment.owxWebUrl,
        });
    }

    showChatHistory() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '600px';
        dialogConfig.height = '400px';
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(
            ChatAssistantHistoryDialogComponent,
            dialogConfig
        );
        dialogRef.afterClosed().subscribe();
    }
}
