import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OwxApiService } from "app/shared/services/generics/owx-api.service";
import { BehaviorSubject, Observable, tap, switchMap } from "rxjs";
import { TrueSelfTemplateGlobalPrinciple, TrueSelfTemplateThinking, TrueSelfTemplateSkill, TrueSelfTemplateHealthyHabit, TrueSelfTemplatePersonalityDevelop, TrueSelfTemplate } from "../trues-self-template/models/true-self-template";

@Injectable({
    providedIn: 'root',
})
export class TrueSelfTemplateDataService extends OwxApiService {
    private _globalPrinciples: BehaviorSubject<TrueSelfTemplateGlobalPrinciple[]> = new BehaviorSubject<TrueSelfTemplateGlobalPrinciple[]>(null);
    private _thinking: BehaviorSubject<TrueSelfTemplateThinking[]> = new BehaviorSubject<TrueSelfTemplateThinking[]>(null);
    private _skills: BehaviorSubject<TrueSelfTemplateSkill[]> = new BehaviorSubject<TrueSelfTemplateSkill[]>(null);
    private _healthyHabits: BehaviorSubject<TrueSelfTemplateHealthyHabit[]> = new BehaviorSubject<TrueSelfTemplateHealthyHabit[]>(null);
    private _personalityDevelop: BehaviorSubject<TrueSelfTemplatePersonalityDevelop[]> = new BehaviorSubject<TrueSelfTemplatePersonalityDevelop[]>(null);

    get globalPrinciples$(): Observable<TrueSelfTemplateGlobalPrinciple[]> {
        return this._globalPrinciples.asObservable();
    }

    get thinking$(): Observable<TrueSelfTemplateThinking[]> {
        return this._thinking.asObservable();
    }

    get skills$(): Observable<TrueSelfTemplateSkill[]> {
        return this._skills.asObservable();
    }

    get healthyHabits$(): Observable<TrueSelfTemplateHealthyHabit[]> {
        return this._healthyHabits.asObservable();
    }

    get personalityDevelop$(): Observable<TrueSelfTemplatePersonalityDevelop[]> {
        return this._personalityDevelop.asObservable();
    }

    createTrueSelfTemplate(trueSelfTemplate: TrueSelfTemplate){
        return this.post("TrueSelfTemplate/Header", trueSelfTemplate);
    }

    getTrueSelfTemplateByUser(userId: string):Observable<any>{
        return this.get("TrueSelfTemplate/ByUser/"+userId);
    }

    gettrueSelfTemplateGlobalPrinciples(trueSelfTemplateBoardId: string): Observable<TrueSelfTemplateGlobalPrinciple[]> {
        return this.get<TrueSelfTemplateGlobalPrinciple[]>(`TrueSelfTemplate/get-trueSelfTemplate-global-principles?TrueSelfTemplateId=${trueSelfTemplateBoardId}`).pipe(
            tap((res:TrueSelfTemplateGlobalPrinciple[]) => {
                res.forEach(element => {
                    element.trueSelfTemplateId = trueSelfTemplateBoardId;
                });
                this._globalPrinciples.next(res);
                return res;
            })
        );
    }

    createtrueSelfTemplateGlobalPrinciple(trueSelfTemplateGlobalPrinciple: TrueSelfTemplateGlobalPrinciple): Observable<any> {
        return this.post("TrueSelfTemplate/create-trueSelfTemplate-global-principle", trueSelfTemplateGlobalPrinciple).pipe(
            switchMap(res => {
                return this.gettrueSelfTemplateGlobalPrinciples(trueSelfTemplateGlobalPrinciple.trueSelfTemplateId);
            })
        );
    }

    updatetrueSelfTemplateGlobalPrinciple(trueSelfTemplateGlobalPrinciple: TrueSelfTemplateGlobalPrinciple): Observable<any> {
        return this.put("TrueSelfTemplate/update-trueSelfTemplate-global-principle", trueSelfTemplateGlobalPrinciple).pipe(
            switchMap(res => {
                return this.gettrueSelfTemplateGlobalPrinciples(trueSelfTemplateGlobalPrinciple.trueSelfTemplateId);
            })
        );
    }

    deletetrueSelfTemplateGlobalPrinciple(trueSelfTemplateBoardId: string, trueSelfTemplateGlobalPrincipleId: string): Observable<any> {
        return this.delete(`TrueSelfTemplate/delete-trueSelfTemplate-global-principle?TrueSelfTemplateId=${trueSelfTemplateBoardId}&globalPrincipleId=${trueSelfTemplateGlobalPrincipleId}`).pipe(
            switchMap(res => {
                return this.gettrueSelfTemplateGlobalPrinciples(trueSelfTemplateBoardId);
            })
        );
    }

    getSkills(trueSelfTemplateBoardId: string): Observable<TrueSelfTemplateSkill[]> {
        return this.get<TrueSelfTemplateSkill[]>(`TrueSelfTemplate/get-trueSelfTemplate-skills?TrueSelfTemplateId=${trueSelfTemplateBoardId}`).pipe(
            tap((res:TrueSelfTemplateSkill[]) => {
                res.forEach(e=>{
                    e.trueSelfTemplateId = trueSelfTemplateBoardId;
                });
                this._skills.next(res);
                return res;
            })
        );
    }

    createSkill(skill: TrueSelfTemplateSkill): Observable<any> {
        return this.post("TrueSelfTemplate/create-trueSelfTemplate-skill", skill).pipe(
            switchMap(res => {
                return this.getSkills(skill.trueSelfTemplateId);
            })
        );
    }

    updateSkill(skill: TrueSelfTemplateSkill): Observable<any> {
        return this.put("TrueSelfTemplate/update-trueSelfTemplate-skill", skill).pipe(
            switchMap(res => {
                return this.getSkills(skill.trueSelfTemplateId);
            })
        );
    }

    deleteSkill(trueSelfTemplateBoardId: string, skillId: string): Observable<any> {
        return this.delete(`TrueSelfTemplate/delete-trueSelfTemplate-skill?TrueSelfTemplateId=${trueSelfTemplateBoardId}&skillId=${skillId}`).pipe(
            switchMap(res => {
                return this.getSkills(trueSelfTemplateBoardId);
            })
        );
    }

    getPersonalityDevelops(trueSelfTemplateBoardId: string): Observable<TrueSelfTemplatePersonalityDevelop[]> {
        return this.get<TrueSelfTemplatePersonalityDevelop[]>(`TrueSelfTemplate/get-trueSelfTemplate-personality-develops?TrueSelfTemplateId=${trueSelfTemplateBoardId}`).pipe(
            tap((res:TrueSelfTemplatePersonalityDevelop[]) => {
                res.forEach(e=>{
                    e.trueSelfTemplateId = trueSelfTemplateBoardId;
                });
                this._personalityDevelop.next(res);
                return res;
            })
        );
    }

    createPersonalityDevelop(personalityDevelop: TrueSelfTemplatePersonalityDevelop): Observable<any> {
        return this.post("TrueSelfTemplate/create-trueSelfTemplate-personality-develop", personalityDevelop).pipe(
            switchMap(res => {
                return this.getPersonalityDevelops(personalityDevelop.trueSelfTemplateId);
            })
        );
    }

    updatePersonalityDevelop(personalityDevelop: TrueSelfTemplatePersonalityDevelop): Observable<any> {
        return this.put("TrueSelfTemplate/update-trueSelfTemplate-personality-develop", personalityDevelop).pipe(
            switchMap(res => {
                return this.getPersonalityDevelops(personalityDevelop.trueSelfTemplateId);
            })
        );
    }

    deletePersonalityDevelop(trueSelfTemplateBoardId: string, personalityDevelopId: string): Observable<any> {
        return this.delete(`TrueSelfTemplate/delete-trueSelfTemplate-personality-develop?TrueSelfTemplateId=${trueSelfTemplateBoardId}&personalityDevelopId=${personalityDevelopId}`).pipe(
            switchMap(res => {
                return this.getPersonalityDevelops(trueSelfTemplateBoardId);
            })
        );
    }

    getThinkings(trueSelfTemplateBoardId: string): Observable<TrueSelfTemplateThinking[]> {
        return this.get<TrueSelfTemplateThinking[]>(`TrueSelfTemplate/get-trueSelfTemplate-thinkings?TrueSelfTemplateId=${trueSelfTemplateBoardId}`).pipe(
            tap((res:TrueSelfTemplateThinking[]) => {
                res.forEach(e=>{
                    e.trueSelfTemplateId = trueSelfTemplateBoardId;
                });
                this._thinking.next(res);
                return res;
            })
        );
    }

    createThinking(thinking: TrueSelfTemplateThinking): Observable<any> {
        return this.post("TrueSelfTemplate/create-trueSelfTemplate-thinking", thinking).pipe(
            switchMap(res => {
                return this.getThinkings(thinking.trueSelfTemplateId);
            })
        );
    }

    updateThinking(thinking: TrueSelfTemplateThinking): Observable<any> {
        return this.put("TrueSelfTemplate/update-trueSelfTemplate-thinking", thinking).pipe(
            switchMap(res => {
                return this.getThinkings(thinking.trueSelfTemplateId);
            })
        );
    }

    deleteThinking(trueSelfTemplateBoardId: string, thinkingId: string): Observable<any> {
        return this.delete(`TrueSelfTemplate/delete-trueSelfTemplate-thinking?TrueSelfTemplateId=${trueSelfTemplateBoardId}&thinkingId=${thinkingId}`).pipe(
            switchMap(res => {
                return this.getThinkings(trueSelfTemplateBoardId);
            })
        );
    }

    getHealthyHabits(trueSelfTemplateBoardId: string): Observable<TrueSelfTemplateHealthyHabit[]> {
        return this.get<TrueSelfTemplateHealthyHabit[]>(`TrueSelfTemplate/get-trueSelfTemplate-healthy-habits?TrueSelfTemplateId=${trueSelfTemplateBoardId}`).pipe(
            tap((res:TrueSelfTemplateHealthyHabit[]) => {
                res.forEach(e=>{
                    e.trueSelfTemplateId = trueSelfTemplateBoardId;
                });
                this._healthyHabits.next(res);
                return res;
            })
        );
    }

    createHealthyHabit(healthyHabit: TrueSelfTemplateHealthyHabit): Observable<any> {
        return this.post("TrueSelfTemplate/create-trueSelfTemplate-healthy-habit", healthyHabit).pipe(
            switchMap(res => {
                return this.getHealthyHabits(healthyHabit.trueSelfTemplateId);
            })
        );
    }

    updateHealthyHabit(healthyHabit: TrueSelfTemplateHealthyHabit): Observable<any> {
        return this.put("TrueSelfTemplate/update-trueSelfTemplate-healthy-habit", healthyHabit).pipe(
            switchMap(res => {
                return this.getHealthyHabits(healthyHabit.trueSelfTemplateId);
            })
        );
    }

    deleteHealthyHabit(trueSelfTemplateBoardId: string, healthyHabitId: string): Observable<any> {
        return this.delete(`TrueSelfTemplate/delete-trueSelfTemplate-healthy-habit?TrueSelfTemplateId=${trueSelfTemplateBoardId}&healthyHabitId=${healthyHabitId}`).pipe(
            switchMap(res => {
                return this.getHealthyHabits(trueSelfTemplateBoardId);
            })
        );
    }
    
    updateTrueSelfTemplateCopies(data) {
        return this.post("TrueSelfTemplate/update-copies",data) ;
    }
}