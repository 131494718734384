import { HttpEventType } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadProgressService } from 'app/shared/services/file-upload-progress.service';
import { FileManageApiService } from 'app/shared/services/generics/file-upload-api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'owx-true-self-template-type-image-dialog',
  templateUrl: './true-self-template-type-image-dialog.component.html',
  styleUrl: './true-self-template-type-image-dialog.component.scss'
})
export class TrueSelfTemplateTypeImageDialogComponent {
  private readonly requestUrl = 'upload-trueSelf-template-type-image/';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fileUploadService: FileManageApiService,
    private toastrService: ToastrService,
    private dialog: MatDialogRef<TrueSelfTemplateTypeImageDialogComponent>,
    private fileUploadProgressService: FileUploadProgressService) {
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('trueSelfTemplateId', this.data.id);
    formData.append('trueSelfTemplateType', this.data.type);
    formData.append('typeId', this.data.typeId);
    this.fileUploadService.post(this.requestUrl, formData, null, true, "events").subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round((100 * event.loaded) / event.total);
        setTimeout(() => {
          this.fileUploadProgressService.setProgress(percentDone);
        }, 500); 
      } else if (event.type === HttpEventType.Response) {
        this.toastrService.success('Upload successful');
        this.dialog.close(true);
      }
    });
  }

  onCancel(){
    this.dialog.close(true);
  }
}
